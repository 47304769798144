nav ul {
    list-style: none;
    display: flex;
    background-color:#007bff ;
    margin-bottom: 20px;
  }
  
  nav ul li {
    padding: 20px;
    color: white;
    font-weight: bold;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  .current {
    border-bottom: 4px solid white;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  p {
    margin-bottom: 15px;
  }
  .login-form{
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    height: 100%;
  }
  .navMain{
    list-style: none;
    display: flex;
    background-color:#007bff ;
    margin-bottom: 40px;
    height: 100%;
    min-height: 65px;
  }
  .navlink{
    padding: 20px;
    color: white;
    font-weight: bold;
  }