#optimizer-alert .alert {
    padding:0.25rem 1rem;
    border-bottom: 1px solid #226471;
    margin-bottom: 0;
}
#optimizer-alert .hidden {
    display: none !important;
}
#optimizer-alert div .message {
    text-align: center;
}
