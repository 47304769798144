.App {
  text-align: left;
  margin-top: 0px;
}

.app-container {
  min-height: 95vh;
}

.footer-container {
  border-top: 1px solid #ccc;
  font-size: 14px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
navbar{
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: rgb(73,79,82);
  color: white
}
.navbar-link{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  background: rgb(73,79,82);
  color: white;
  min-height: 10vh;
}
.App .navbar-brand {
  font-weight: bold;
}

.App .container{
  align-content: center
}

::placeholder {
  color: #c1c7cb !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #c1c7cb !important;
}

::-ms-input-placeholder {
  color: #c1c7cb !important;
}

.h-scroll-container {
  overflow: scroll;
  max-width: 100vw;
  max-height: 95vh;
}

.assigned-container>table,
.unassigned-container>table{
  border-top:0;
}

.assigned-container > table thead:first-child th,
.unassigned-container>table thead:first-child th {
  position: -webkit-sticky;
  position: sticky;
  background: #fff3cd;
  color: #856404;
  top: 0;
  z-index: 5;
  border-right:1px solid #dee2e6;
  border-top:0;
  border-bottom:0;
}
.unassigned-container>table thead:first-child th {
  background: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
.assigned-container > table thead:nth-child(2) th,
.unassigned-container>table thead:nth-child(2) th {
  position: -webkit-sticky;
  position: sticky;
  background: #000;
  color: #FFF;
  top: 49px;
  z-index: 5;
  border-right:1px solid #dee2e6;
}
.unassigned-container>table thead:nth-child(2) th {
  top: 32px;
}

/* safari hack*/
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) and (display:flow-root) { 
    .assigned-container > table thead:nth-child(2) th { top:0; } 
  } 
}

.assigned-container > table thead:nth-child(2) th:first-child,
.unassigned-container>table thead:nth-child(2) th:first-child {
  left: 0;
  z-index: 6;
}
.assigned-container > table tbody th,
.unassigned-container>table tbody th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}
.assigned-container > table tbody tr:nth-of-type(odd) th,
.unassigned-container>table tbody tr:nth-of-type(odd) th {
  background-color: #f5f5f5;
}
.assigned-container > table tbody tr:nth-of-type(even) th,
.unassigned-container>table tbody tr:nth-of-type(even) th {
  background-color: #ffffff;
}

.unassigned-container {
  resize: vertical;
  overflow: auto;
}
.react-time-input-picker-wrapper{border-bottom: none !important;}
.react-time-input-picker input {
  background:#fff !important;
  border: 1px solid rgb(206,212,218) !important;
  border-radius: 4px !important;
  height:37.9861px !important;
  color:rgb(73,80,87) !important;
  min-width: 45px;
}
.react-time-input-picker input:focus{
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
