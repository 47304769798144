.standardcode{

    padding-left:10px ;
}
.standardcodes-dropdown{
width: 300px !important;
}
.containerAdd{
    width:70%;
}
.thread{
    background-color: #212529;
    color: white;
    font-weight: bold;
}
.cliniclist{
    width:100%;
    padding-right: 15px;
    padding-left:15px;
}
.btnEdit{
    background-image: url("../../images/edit.png");
    background-position: center;
    background-repeat: no-repeat !important;
    border-radius: 6px !important;
    height: 100px; 
    width: 100px; 
    max-width: 300px; 
    max-height: 300px;
}
.btnDelete{
    background-image: url("../../images/delete.png")  !important;
    background-repeat: no-repeat !important;
    height: 50px;
    width: 50px;
    background-position: center;
    border-radius: 6px !important;
}