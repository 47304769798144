.btn-space {
    margin-right: 5px;
}
.navbar-light .navbar-nav  {
    color: red !important;
}
.nav-link{
    color: white !important;
    font-size: 17px;
    font-weight: bold;
}