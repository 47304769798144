.progress-indicator-container{
    height: 8px;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 50px;
    position: relative;
}
.progress-indicator-container .progress-indicator{
    height: 100%;
    border-radius: inherit;
}
.progress-indicator-container .tooltip {
    border-radius: 3px;
    display: inline-block;
    font-size: 13px;
    opacity: 0;
    padding: 8px 21px;
    position: fixed;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    visibility: hidden;
    z-index: 999;
                
    background-color: #222;
    color: #fff;
}
.progress-indicator-container .tooltip::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
    z-index: 999;
}
.progress-indicator-container:hover .tooltip {
    opacity: 0.9;
    margin-top: 0;
    margin-left: 0;
    visibility: visible;
}
